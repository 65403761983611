// 申遗承诺
import DJZT from './sn-table-column-djzt';
import SHZT from './sn-table-column-shzt';
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                // {
                //     label: "任务名称",
                //     prop: "RWMC",
                //     width: "180"
                // },
                {
                    label: "遗产组成",
                    prop: "YCYSFL_2",
                    minWidth: "60"
                },
                {
                    label: "上报类型",
                    prop: "YCLXNAME",
                    width: "120"
                },
                {
                    label: "评估",
                    prop: "PG_DESC",
                    width: "80"
                },
                {
                    label: "事件描述",
                    prop: "SJMS",
                },
                {
                    label: "上报人",
                    prop: "CJRMC",
                    width: "120"
                },
                {
                    label: "上报人电话",
                    prop: "SBRDH",
                    width: "120"
                },
                {
                    label: "上报时间",
                    prop: "SBSJ",
                    width: "160"
                },
                {
                    label: "是否转为异常",
                    prop: "SFZYC",
                    width: "70",
                    transList: {
                        null: '否',
                        '': '否',
                        0: '否',
                        1: '是'
                    },
                },
                {
                    label: "附件",
                    prop: "LJ",
                    showFileBtn: true,
                    width: "50"
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;